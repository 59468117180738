<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增摄像头"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="摄像头名称">
          <a-select
              v-decorator="['code', {
              rules: [
                { required: true, message: '请选择摄像头' }
              ]
            }]"
              placeholder="请选择摄像头"
              @load="loadingCameraOptions"
              @focus="fetchCameraOptions"
              show-search
              :filter-option="filterOption"
              option-filter-prop="children"
              @change="changeCamera"
          >
            <a-select-option
                v-for="camera in cameraOptions"
                :key="camera.code"
                :value="camera.code"
            >
              {{ camera.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

<!--        <a-form-item label="摄像头名称">-->
<!--          <a-input-->
<!--            v-decorator="['name', {-->
<!--              normalize: this.$lodash.trim,-->
<!--              rules: [-->
<!--                { required: true, message: '请输入摄像头名称' },-->
<!--                { max: 20, message: '最多20个字符' },-->
<!--              ]-->
<!--            }]"-->
<!--          />-->
<!--        </a-form-item>-->

        <a-form-item label="通道号">
          <a-input-number
            :min="1"
            :max="10000000"
            :step="1"
            :precision="0"
            style="width: 100%"
            v-decorator="['no', {
              rules: [
                { required: true, message: '请输入通道号' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="厅房">
          <a-select
            v-decorator="['hall_id', {
              rules: [
                { required: true, message: '请选择厅房' }
              ]
            }]"
            placeholder="请选择厅房"
            @load="loadingHallOptions"
            @focus="fetchHallOptions"
            show-search
            :filter-option="filterOption"
            option-filter-prop="children"
          >
            <a-select-option
              v-for="hall in hallOptions"
              :key="hall.id"
              :value="hall.id"
            >
              {{ hall.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { createCamera } from '@/api/camera'
import { findHallWithFuneralHomeOptions } from '@/api/hall'
import {findCameraResources} from "@/api/hikvision";

export default {
  name: 'NewCamera',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    equipmentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'camera' }),
      submitting: false,
      loadingHallOptions: false,
      hallOptions: [],
      cameraOptions: [],
      loadingCameraOptions: false,
      name: '',
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    changeCamera(val){
      this.cameraOptions.forEach(res => {
        if (res.code === val) {
          this.name = res.name
        }
      })
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    fetchHallOptions() {
      if (this.hallOptions.length > 0) {
        return
      }
      this.loadingHallOptions = true
      findHallWithFuneralHomeOptions().then((res) => {
        if (res.code === 0) {
          this.hallOptions = res.data.options
        }
        this.loadingHallOptions = false
      })
    },

    fetchCameraOptions(){
      if (this.cameraOptions.length > 0) {
        return
      }
      this.loadingCameraOptions = true
      findCameraResources().then((res) => {
        if (res.code === 0) {
          this.cameraOptions = res.data
        }
        this.loadingCameraOptions = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            equipment_id: this.equipmentId,
            name: this.name
          }
          createCamera(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
