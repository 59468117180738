import request from '@/utils/request'

// 更新厅房
export function updateHall(hallId, data) {
  return request({
    url: `/halls/${hallId}/update`,
    method: 'put',
    data
  })
}

// 更新厅房有效性
export function updateHallEffective(hallId, data) {
  return request({
    url: `/halls/${hallId}/effective`,
    method: 'patch',
    data
  })
}

// 更新工作状态
export function updateHallStatus(hallId, data) {
  return request({
    url: `/halls/${hallId}/status`,
    method: 'patch',
    data
  })
}

// 添加厅房
export function createHall(id, data) {
  return request({
    url: `/halls/${id}`,
    method: 'post',
    data
  })
}

// 查询所有厅房
export function findHalls(id, params) {
  return request({
    url: `/halls/${id}`,
    method: 'get',
    params: params
  })
}

// 获取厅房下拉选项
export function findHallOptions(params) {
  return request({
    url: `/halls/options`,
    method: 'get',
    params: params
  })
}

// 查询带殡仪馆的厅房选项
export function findHallWithFuneralHomeOptions(params) {
  return request({
    url: `/halls/with_funeral_home/options`,
    method: 'get',
    params: params
  })
}

// 查询厅房
export function findHall(hallId) {
  return request({
    url: `/touch_screen/halls/${hallId}`,
    method: 'get'
  })
}

// 查询所有厅房
export function findHallsByOrder(params) {
  return request({
    url: `/halls/by_order`,
    method: 'get',
    params: params
  })
}

// 查询所有厅房
export function findHallsById(params) {
  return request({
    url: `/halls/by_id`,
    method: 'get',
    params: params
  })
}

// 获取所有厅房下拉选项
export function findHallAllOptions() {
  return request({
    url: `/halls/all/options`,
    method: 'get'
  })
}

// 获取所有厅房树状下拉选项
export function findHallAllTreeOptions() {
  return request({
    url: `/halls/all/tree_options`,
    method: 'get'
  })
}
